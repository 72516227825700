import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDnnJq9tvEeL4iVvVljBpxEgsP6E42qRPM",
  authDomain: "valorlink-d8697.firebaseapp.com",
  projectId: "valorlink-d8697",
  storageBucket: "valorlink-d8697.firebasestorage.app",
  messagingSenderId: "385287387655",
  appId: "1:385287387655:web:3b498f74c430f2848a48a2",
  measurementId: "G-JBKJT1JM6P"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);