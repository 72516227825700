import React from 'react';

function AwardsPage() {
  return (
    <div>
      <h2 className="text-3xl font-bold mb-6">Community Awards</h2>
      <p className="text-gray-300">Awards and achievements for this community.</p>
    </div>
  );
}

export default AwardsPage;
