import React, { useEffect, useState } from 'react';
import { useParams, Link, Outlet, useNavigate } from 'react-router-dom';
import { doc, onSnapshot, collection, query, where, getDocs, updateDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { getAuth } from 'firebase/auth';
import { motion } from 'framer-motion';

function CommunityPage() {
  const { id } = useParams();
  const [community, setCommunity] = useState(null);
  const [metrics, setMetrics] = useState({
    totalMembers: 0,
    activeMembers: 0,
    totalEvents: 0,
    upcomingEvents: 0,
  });
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isMember, setIsMember] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, 'communities', id), async (snapshot) => {
      if (snapshot.exists()) {
        const communityData = snapshot.data();
        setCommunity(communityData);

        if (communityData.members?.includes(currentUser?.uid)) {
          setIsMember(true);
        }

        const totalMembers = communityData.members?.length || 0;

        // Fetch announcements
        const announcementsCollection = collection(db, 'announcements');
        const announcementsQuery = query(announcementsCollection, where('communityId', '==', id));
        const announcementsSnapshot = await getDocs(announcementsQuery);
        const announcementsList = announcementsSnapshot.docs.map((doc) => doc.data());

        // Fetch events
        const eventsCollection = collection(db, 'events');
        const eventsQuery = query(eventsCollection, where('communityId', '==', id));
        const eventsSnapshot = await getDocs(eventsQuery);
        const events = eventsSnapshot.docs.map((doc) => doc.data());

        const totalEvents = events.length;
        const upcomingEvents = events.filter(
          (event) => new Date(event.date.seconds * 1000) > new Date()
        ).length;

        // Update metrics and announcements
        setMetrics({
          totalMembers,
          activeMembers: totalMembers,
          totalEvents,
          upcomingEvents,
        });
        setAnnouncements(announcementsList);
      } else {
        console.error('Community not found');
        navigate('/explore');
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [id, navigate, currentUser?.uid]);

  const handleJoin = async () => {
    try {
      if (!currentUser) {
        alert('You must be logged in to join a community.');
        navigate('/login');
        return;
      }

      const communityDocRef = doc(db, 'communities', id);
      await updateDoc(communityDocRef, {
        members: arrayUnion(currentUser.uid),
      });

      setIsMember(true);
      alert(`Successfully joined ${community.name}`);
    } catch (error) {
      console.error('Error joining community:', error);
      alert('Failed to join community. Please try again.');
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <p className="text-lg font-bold">Loading community...</p>
        </motion.div>
      </div>
    );
  }

  if (!community) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <p className="text-lg font-bold">Community not found.</p>
        </motion.div>
      </div>
    );
  }

  return (
    <motion.div
      className="min-h-screen bg-gray-900 text-white"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {/* Community Header */}
      <header className="bg-gradient-to-r from-blue-700 to-blue-500 p-6 shadow-lg">
        <motion.div
          className="container mx-auto flex flex-col md:flex-row justify-between items-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
        >
          <div>
            <h1 className="text-3xl font-extrabold">{community.name}</h1>
            <p className="text-gray-200 mt-2 text-lg">{community.description}</p>
          </div>
          <div className="flex items-center mt-4 md:mt-0">
            <p className="text-gray-100 mr-4 text-sm">
              Created on: {new Date(community.createdAt.seconds * 1000).toLocaleDateString()}
            </p>
            {!isMember && (
              <button
                onClick={handleJoin}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg shadow-lg hover:bg-blue-500 transition"
              >
                Join
              </button>
            )}
          </div>
        </motion.div>
      </header>

      {/* Sub-Navigation Menu */}
      <motion.nav
        className="bg-gray-800 p-4 shadow-md sticky top-0 z-10"
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="container mx-auto flex space-x-4">
          <Link to={`/community/${id}`} className="hover:text-blue-400 transition">
            Dashboard
          </Link>
          <Link to={`/community/${id}/members`} className="hover:text-blue-400 transition">
            Members
          </Link>
          <Link to={`/community/${id}/events`} className="hover:text-blue-400 transition">
            Events
          </Link>
          <Link to={`/community/${id}/awards`} className="hover:text-blue-400 transition">
            Awards
          </Link>
          <Link to={`/community/${id}/structure`} className="hover:text-blue-400 transition">
            Structure
          </Link>
        </div>
      </motion.nav>

      {/* Dashboard Content or Subpage */}
      <main className="container mx-auto p-6 space-y-6">
        {window.location.pathname === `/community/${id}` ? (
          <>
            {/* Metrics */}
            <motion.div
              className="grid grid-cols-1 md:grid-cols-4 gap-6"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, staggerChildren: 0.2 }}
            >
              {[
                { label: 'Members', value: metrics.totalMembers },
                { label: 'Active Members', value: metrics.activeMembers },
                { label: 'Events', value: metrics.totalEvents },
                { label: 'Upcoming Events', value: metrics.upcomingEvents },
              ].map((metric, index) => (
                <motion.div
                  key={index}
                  className="bg-gray-800 p-6 rounded-lg text-center shadow-lg"
                  whileHover={{ scale: 1.05 }}
                >
                  <h3 className="text-blue-400 text-xl font-bold">{metric.label}</h3>
                  <p className="text-gray-200 text-3xl font-extrabold">{metric.value}</p>
                </motion.div>
              ))}
            </motion.div>

            {/* Announcements */}
            <motion.div
              className="bg-gray-800 p-6 rounded-lg"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <h3 className="text-blue-400 text-xl font-bold mb-4">Announcements</h3>
              <ul className="space-y-4">
                {announcements.map((announcement, index) => (
                  <li key={index} className="text-gray-300">
                    <strong>{new Date(announcement.date.seconds * 1000).toLocaleDateString()}:</strong>{' '}
                    {announcement.message}
                  </li>
                ))}
              </ul>
            </motion.div>
          </>
        ) : (
          <Outlet />
        )}
      </main>
    </motion.div>
  );
}

export default CommunityPage;
