import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactFlow, {
  addEdge,
  MiniMap,
  Controls,
  Background,
} from 'react-flow-renderer';
import { db } from '../firebaseConfig';
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  doc,
  updateDoc,
} from 'firebase/firestore';

function StructurePage() {
  const { id: communityId } = useParams();
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchGroups() {
      try {
        const groupsCollection = collection(db, 'subgroups');
        const groupsQuery = query(groupsCollection, where('communityId', '==', communityId));
        const groupsSnapshot = await getDocs(groupsQuery);

        const groupList = groupsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setGroups(groupList);

        // Convert to React Flow Nodes and Edges
        const flowNodes = groupList.map((group) => ({
          id: group.id,
          data: { label: group.name, group },
          position: { x: Math.random() * 400, y: Math.random() * 400 },
        }));

        const flowEdges = groupList
          .filter((group) => group.parentGroupId)
          .map((group) => ({
            id: `e${group.parentGroupId}-${group.id}`,
            source: group.parentGroupId,
            target: group.id,
          }));

        setNodes(flowNodes);
        setEdges(flowEdges);
      } catch (error) {
        console.error('Error fetching subgroups:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchGroups();
  }, [communityId]);

  const handleNodeClick = (event, node) => {
    setSelectedNode(node);
  };

  const handleNodeAdd = async (parentNodeId) => {
    const newNodeName = prompt('Enter name for the new subgroup:');
    if (!newNodeName) return;

    try {
      const groupsCollection = collection(db, 'subgroups');
      const newDoc = await addDoc(groupsCollection, {
        name: newNodeName,
        communityId,
        parentGroupId: parentNodeId,
        members: [],
      });

      // Update React Flow
      const newNode = {
        id: newDoc.id,
        data: { label: newNodeName },
        position: { x: Math.random() * 400, y: Math.random() * 400 },
      };

      const newEdge = {
        id: `e${parentNodeId}-${newDoc.id}`,
        source: parentNodeId,
        target: newDoc.id,
      };

      setNodes((prevNodes) => [...prevNodes, newNode]);
      setEdges((prevEdges) => [...prevEdges, newEdge]);

      alert('Subgroup added successfully!');
    } catch (error) {
      console.error('Error adding subgroup:', error);
      alert('Failed to add subgroup. Please try again.');
    }
  };

  const handleDragEnd = async (event, node) => {
    // Persist the new position if needed
    console.log(`Node ${node.id} moved to`, node.position);
    // Optionally update Firestore with the new position
  };

  const handleEdgeUpdate = (oldEdge, newConnection) => {
    setEdges((eds) => addEdge(newConnection, eds));
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
        <p>Loading structure...</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white p-6">
      <h1 className="text-3xl font-bold text-blue-400 mb-6">Community Structure</h1>

      <div className="h-[600px] bg-gray-800 rounded-lg shadow-md relative">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodeClick={handleNodeClick}
          onNodeDragStop={handleDragEnd}
          onConnect={handleEdgeUpdate}
          fitView
        >
          <MiniMap />
          <Controls />
          <Background />
        </ReactFlow>
      </div>

      {/* Selected Node Details */}
      {selectedNode && (
        <div className="mt-6 bg-gray-800 p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-bold text-blue-300">Selected Group: {selectedNode.data.label}</h2>
          <p className="text-gray-400">ID: {selectedNode.id}</p>
          <button
            onClick={() => handleNodeAdd(selectedNode.id)}
            className="px-4 py-2 mt-4 bg-blue-600 text-white rounded-lg hover:bg-blue-500"
          >
            Add Subgroup
          </button>
        </div>
      )}
    </div>
  );
}

export default StructurePage;
