import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import SignUpPage from './pages/SignUpPage';
import LoginPage from './pages/LoginPage';
import PlayerProfile from './pages/PlayerProfile';
import CreateCommunityPage from './pages/CreateCommunityPage';
import ExploreDirectoryPage from './pages/ExploreDirectoryPage';
import CommunityPage from './pages/CommunityPage';
import MemberDirectory from './components/MemberDirectory';
import EventsPage from './components/EventsPage';
import AwardsPage from './components/AwardsPage';
import StructurePage from './components/StructurePage';



function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/profile" element={<PlayerProfile />} />
        <Route path="/create-community" element={<CreateCommunityPage />} />
        <Route path="/explore" element={<ExploreDirectoryPage />} />
        <Route path="/community/:id" element={<CommunityPage />}>
          <Route path="members" element={<MemberDirectory />} />
          <Route path="events" element={<EventsPage />} />
          <Route path="awards" element={<AwardsPage />} />
          <Route path="structure" element={<StructurePage />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
