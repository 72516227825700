import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

function CreateCommunityPage() {
  const [communityData, setCommunityData] = useState({
    name: '',
    description: '',
    tags: '',
    visibility: 'public',
  });

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null); // Track the logged-in user's ID
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
        setCurrentUserId(user.uid); // Set the user's ID
      } else {
        setIsAuthenticated(false);
        setCurrentUserId(null);
        navigate('/login'); // Redirect to login if not authenticated
      }
    });

    return () => unsubscribe();
  }, [auth, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCommunityData({ ...communityData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    if (!communityData.name || !communityData.description) {
      setError('Please fill out all required fields.');
      setLoading(false);
      return;
    }

    try {
      if (!currentUserId) {
        throw new Error('User is not authenticated.');
      }

      // Add community data to Firestore
      const communitiesCollection = collection(db, 'communities');
      await addDoc(communitiesCollection, {
        ...communityData,
        creatorId: currentUserId, // Add creatorId
        createdAt: serverTimestamp(), // Add creation timestamp
      });

      console.log('Community created successfully!');
      navigate(`/explore`); // Redirect to the community list page
    } catch (error) {
      console.error('Error creating community:', error.message);
      setError('Failed to create community. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    isAuthenticated && (
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-gray-200 flex items-center justify-center">
        <div className="w-full max-w-lg p-8 bg-gray-800 rounded-lg shadow-lg space-y-6">
          <h1 className="text-3xl font-bold text-blue-500 text-center">
            Create a Community
          </h1>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-gray-400 mb-1">
                Community Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                value={communityData.name}
                onChange={handleChange}
                className="w-full px-4 py-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter community name"
              />
            </div>
            <div>
              <label htmlFor="description" className="block text-gray-400 mb-1">
                Description
              </label>
              <textarea
                name="description"
                id="description"
                value={communityData.description}
                onChange={handleChange}
                className="w-full px-4 py-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Tell us about your community"
              />
            </div>
            <div>
              <label htmlFor="tags" className="block text-gray-400 mb-1">
                Tags (Comma-separated)
              </label>
              <input
                type="text"
                name="tags"
                id="tags"
                value={communityData.tags}
                onChange={handleChange}
                className="w-full px-4 py-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="E.g., MMO, Milsim, Simulation"
              />
            </div>
            <div>
              <label htmlFor="visibility" className="block text-gray-400 mb-1">
                Visibility
              </label>
              <select
                name="visibility"
                id="visibility"
                value={communityData.visibility}
                onChange={handleChange}
                className="w-full px-4 py-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="public">Public</option>
                <option value="private">Private</option>
              </select>
            </div>
            {error && <p className="text-red-500 text-sm">{error}</p>}
            <button
              type="submit"
              className={`w-full py-3 text-white rounded-lg transition text-lg ${
                loading
                  ? 'bg-gray-500 cursor-not-allowed'
                  : 'bg-blue-600 hover:bg-blue-500'
              }`}
              disabled={loading}
            >
              {loading ? 'Creating...' : 'Create Community'}
            </button>
          </form>
        </div>
      </div>
    )
  );
}

export default CreateCommunityPage;
