import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { FaUserFriends, FaCalendarAlt, FaUsers } from 'react-icons/fa';
import { db } from '../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';

function HomePage() {
  const [stats, setStats] = useState({
    users: 0,
    events: 0,
    communities: 0,
  });

  useEffect(() => {
    async function fetchStats() {
      try {
        const userCount = await getDocs(collection(db, 'users'));
        const eventCount = await getDocs(collection(db, 'events'));
        const communityCount = await getDocs(collection(db, 'communities'));

        setStats({
          users: userCount.size,
          events: eventCount.size,
          communities: communityCount.size,
        });
      } catch (error) {
        console.error('Error fetching stats:', error);
      }
    }

    fetchStats();
  }, []);

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        when: 'beforeChildren',
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <motion.div
      className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-gray-200 flex items-center justify-center"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <motion.main
        className="w-full max-w-4xl flex flex-col items-center justify-center p-10 space-y-8"
        variants={itemVariants}
      >
        {/* Welcome Message */}
        <motion.h1
          className="text-5xl font-extrabold text-blue-500"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
        >
          Welcome to Valorlink
        </motion.h1>
        <motion.p
          className="text-gray-300 text-lg max-w-xl text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
        >
          Connect, organize, and manage your gaming community effortlessly.
        </motion.p>

        {/* Site Stats */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full">
          <motion.div
            className="bg-gray-800 p-6 rounded-lg shadow-lg flex flex-col items-center"
            variants={itemVariants}
          >
            <FaUserFriends className="text-blue-400 text-4xl mb-4" />
            <h3 className="text-xl font-bold text-blue-400">Users</h3>
            <p className="text-3xl font-extrabold text-gray-100">{stats.users}</p>
          </motion.div>
          <motion.div
            className="bg-gray-800 p-6 rounded-lg shadow-lg flex flex-col items-center"
            variants={itemVariants}
          >
            <FaUsers className="text-blue-400 text-4xl mb-4" />
            <h3 className="text-xl font-bold text-blue-400">Communities</h3>
            <p className="text-3xl font-extrabold text-gray-100">{stats.communities}</p>
          </motion.div>
          <motion.div
            className="bg-gray-800 p-6 rounded-lg shadow-lg flex flex-col items-center"
            variants={itemVariants}
          >
            <FaCalendarAlt className="text-blue-400 text-4xl mb-4" />
            <h3 className="text-xl font-bold text-blue-400">Events</h3>
            <p className="text-3xl font-extrabold text-gray-100">{stats.events}</p>
          </motion.div>
        </div>

        {/* What is Valorlink */}
        <motion.div
          className="bg-gray-800 p-6 rounded-lg shadow-lg text-center max-w-3xl"
          variants={itemVariants}
        >
          <h3 className="text-lg font-bold text-blue-400 mb-2">What is Valorlink?</h3>
          <p className="text-gray-300 text-sm leading-relaxed">
            Valorlink is the ultimate platform for gamers and community leaders
            to connect, organize, and thrive. Whether you want to join an
            existing community or create your own, Valorlink makes it simple and
            powerful. Discover events, collaborate with others, and take your
            gaming experience to the next level!
          </p>
        </motion.div>
      </motion.main>
    </motion.div>
  );
}

export default HomePage;
