import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { doc, updateDoc, getDoc } from 'firebase/firestore';

function PlayerProfile() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = location.state || {};

  // Handle cases where the user object is missing
  useEffect(() => {
    if (!user) {
      navigate('/signup'); // Redirect to sign up if user is not available
    }
  }, [user, navigate]);

  const [profileData, setProfileData] = useState({
    avatar: '',
    bio: '',
    favoriteGames: '',
    skillLevel: '',
    interests: [],
    socialMedia: '',
  });

  const [loading, setLoading] = useState(true);

  // Fetch existing user profile data if available
  useEffect(() => {
    const fetchProfileData = async () => {
      if (user && user.id) {
        try {
          const docRef = doc(db, 'users', user.id);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            setProfileData(docSnap.data());
          }
        } catch (error) {
          console.error('Error fetching profile data:', error);
        }
      }
      setLoading(false);
    };

    fetchProfileData();
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const handleMultiSelectChange = (e) => {
    const { options } = e.target;
    const selected = Array.from(options).filter((opt) => opt.selected).map((opt) => opt.value);
    setProfileData({ ...profileData, interests: selected });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userDoc = doc(db, 'users', user.id);
      await updateDoc(userDoc, profileData);
      console.log('Profile updated successfully!');
    } catch (err) {
      console.error('Error updating profile:', err);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center text-gray-200">
        <p>Loading profile...</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-gray-200 flex items-center justify-center">
      <div className="w-full max-w-lg p-8 bg-gray-800 rounded-lg shadow-lg space-y-6">
        <h1 className="text-3xl font-bold text-blue-500 text-center">
          Complete Your Profile
        </h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="avatar" className="block text-gray-400 mb-1">
              Avatar URL
            </label>
            <input
              type="text"
              name="avatar"
              id="avatar"
              value={profileData.avatar}
              onChange={handleChange}
              className="w-full px-4 py-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="bio" className="block text-gray-400 mb-1">
              Bio
            </label>
            <textarea
              name="bio"
              id="bio"
              value={profileData.bio}
              onChange={handleChange}
              className="w-full px-4 py-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="favoriteGames" className="block text-gray-400 mb-1">
              Favorite Games
            </label>
            <input
              type="text"
              name="favoriteGames"
              id="favoriteGames"
              value={profileData.favoriteGames}
              onChange={handleChange}
              className="w-full px-4 py-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="skillLevel" className="block text-gray-400 mb-1">
              Skill Level
            </label>
            <select
              name="skillLevel"
              id="skillLevel"
              value={profileData.skillLevel}
              onChange={handleChange}
              className="w-full px-4 py-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Select Skill Level</option>
              <option value="Beginner">Beginner</option>
              <option value="Intermediate">Intermediate</option>
              <option value="Advanced">Advanced</option>
              <option value="Professional">Professional</option>
            </select>
          </div>
          <div>
            <label htmlFor="interests" className="block text-gray-400 mb-1">
              Interests
            </label>
            <select
              name="interests"
              id="interests"
              multiple
              value={profileData.interests}
              onChange={handleMultiSelectChange}
              className="w-full px-4 py-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="Streaming">Streaming</option>
              <option value="Esports">Esports</option>
              <option value="Content Creation">Content Creation</option>
              <option value="Casual Gaming">Casual Gaming</option>
              <option value="Competitive Gaming">Competitive Gaming</option>
            </select>
          </div>
          <div>
            <label htmlFor="socialMedia" className="block text-gray-400 mb-1">
              Social Media Link
            </label>
            <input
              type="text"
              name="socialMedia"
              id="socialMedia"
              value={profileData.socialMedia}
              onChange={handleChange}
              className="w-full px-4 py-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <button
            type="submit"
            className="w-full py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-500 transition text-lg"
          >
            Save Profile
          </button>
        </form>
      </div>
    </div>
  );
}

export default PlayerProfile;
