import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';

function ExploreDirectoryPage() {
  const [communities, setCommunities] = useState([]);
  const [filteredCommunities, setFilteredCommunities] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);

  // Fetch communities from Firestore
  useEffect(() => {
    async function fetchCommunities() {
      try {
        const communityCollection = collection(db, 'communities');
        const communitySnapshot = await getDocs(communityCollection);

        const communityList = communitySnapshot.docs.map((doc) => {
          const communityData = doc.data();

          // Format createdAt timestamp
          let createdAt = 'Unknown Date';
          if (communityData.createdAt?.seconds) {
            createdAt = new Date(communityData.createdAt.seconds * 1000).toLocaleDateString();
          }

          return {
            id: doc.id,
            ...communityData,
            createdAt,
          };
        });

        setCommunities(communityList);
        setFilteredCommunities(communityList);
      } catch (error) {
        console.error('Error fetching communities:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchCommunities();
  }, []);

  // Filter communities based on search term
  useEffect(() => {
    const results = communities.filter((community) =>
      community.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      community.tags?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCommunities(results);
  }, [searchTerm, communities]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
        <p>Loading communities...</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-gray-200">
      <div className="container mx-auto px-6 py-12">
        <h1 className="text-3xl font-bold text-blue-400 mb-6">Explore Communities</h1>

        {/* Search Bar */}
        <input
          type="text"
          placeholder="Search by name or tags..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-full p-3 mb-6 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        {filteredCommunities.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredCommunities.map((community) => (
              <Link
                to={`/community/${community.id}`}
                key={community.id}
                className="bg-gray-800 p-6 rounded-lg shadow-md hover:shadow-lg transition block"
              >
                <h2 className="text-xl font-bold text-blue-400">{community.name}</h2>
                <p className="text-gray-300 mt-2">{community.description}</p>
                <div className="mt-4 text-sm text-gray-400">
                  Tags: {community.tags || 'None'}
                </div>
                <div className="mt-4 text-sm text-gray-400">
                  Created On: {community.createdAt}
                </div>
              </Link>
            ))}
          </div>
        ) : (
          <p className="text-gray-400">No communities found.</p>
        )}
      </div>
    </div>
  );
}

export default ExploreDirectoryPage;
