import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import Calendar from 'react-calendar'; // Install this library using `npm install react-calendar`
import 'react-calendar/dist/Calendar.css'; // Import calendar styles
import { motion } from 'framer-motion';
import './calendar.css'; // Custom styles for the dark theme


function EventsPage() {
  const { id } = useParams(); // Community ID
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    async function fetchEvents() {
      try {
        const eventsCollection = collection(db, 'events');
        const eventsQuery = query(eventsCollection, where('communityId', '==', id));
        const eventsSnapshot = await getDocs(eventsQuery);

        const eventsList = eventsSnapshot.docs.map((doc) => {
          const eventData = doc.data();
          return {
            id: doc.id,
            ...eventData,
            date: new Date(eventData.date.seconds * 1000), // Convert Firestore timestamp to JS Date
          };
        });

        // Sort events by date (nearest first)
        const sortedEvents = eventsList.sort((a, b) => a.date - b.date);
        setEvents(sortedEvents);
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchEvents();
  }, [id]);

  const renderTileContent = ({ date, view }) => {
    // Highlight days with events
    if (view === 'month') {
      const hasEvent = events.some((event) => event.date.toDateString() === date.toDateString());
      if (hasEvent) {
        return <div className="bg-blue-400 w-2 h-2 rounded-full mx-auto mt-1"></div>;
      }
    }
    return null;
  };

  const upcomingEvents = events.filter((event) => event.date >= new Date());

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <p className="text-lg font-bold">Loading events...</p>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white p-6">
      <h1 className="text-3xl font-bold text-blue-400 mb-6">Community Events</h1>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Calendar */}
        <motion.div
          className="bg-gray-800 p-6 rounded-lg shadow-md"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-xl font-bold text-blue-300 mb-4">Event Calendar</h2>
          <Calendar
            onChange={(date) => setSelectedDate(date)}
            value={selectedDate}
            tileContent={renderTileContent}
            className="react-calendar bg-gray-700 text-white"
          />
        </motion.div>

        {/* Upcoming Events */}
        <motion.div
          className="bg-gray-800 p-6 rounded-lg shadow-md"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-xl font-bold text-blue-300 mb-4">Upcoming Events</h2>
          {upcomingEvents.length > 0 ? (
            <ul className="space-y-4">
              {upcomingEvents.map((event) => (
                <li key={event.id} className="bg-gray-700 p-4 rounded-lg shadow">
                  <h3 className="text-lg font-bold text-blue-400">{event.name}</h3>
                  <p className="text-gray-300 mt-1">{event.description}</p>
                  <p className="text-sm text-gray-400 mt-2">
                    {event.date.toLocaleDateString()} at {event.date.toLocaleTimeString()}
                  </p>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-400">No upcoming events.</p>
          )}
        </motion.div>
      </div>
    </div>
  );
}

export default EventsPage;
