import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { motion } from 'framer-motion';

function MemberDirectory() {
  const { id } = useParams(); // Community ID
  const [members, setMembers] = useState([]); // Store member details
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchMembers() {
      try {
        // Fetch the community document
        const communityDocRef = doc(db, 'communities', id);
        const communitySnapshot = await getDoc(communityDocRef);

        if (communitySnapshot.exists()) {
          const communityData = communitySnapshot.data();
          const memberIds = communityData.members || [];

          // Fetch details for each member ID
          const memberDetails = await Promise.all(
            memberIds.map(async (uid) => {
              const userDocRef = doc(db, 'users', uid);
              const userSnapshot = await getDoc(userDocRef);
              if (userSnapshot.exists()) {
                return { uid, ...userSnapshot.data() };
              }
              return null; // Handle missing user documents gracefully
            })
          );

          // Filter out any null entries (if user documents are missing)
          setMembers(memberDetails.filter(Boolean));
        } else {
          console.error('Community not found');
        }
      } catch (error) {
        console.error('Error fetching members:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchMembers();
  }, [id]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <p className="text-lg font-bold">Loading members...</p>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white p-6">
      <h1 className="text-3xl font-bold text-blue-400 mb-6">Community Members</h1>
      {members.length > 0 ? (
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, staggerChildren: 0.2 }}
        >
          {members.map((member) => (
            <motion.div
              key={member.uid}
              className="bg-gray-800 p-6 rounded-lg shadow-md hover:shadow-lg transition"
              whileHover={{ scale: 1.05 }}
            >
              <h2 className="text-xl font-bold text-blue-400">{member.name}</h2>
              <p className="text-gray-300 mt-2">Rank: {member.rank || 'Member'}</p>
              <p
                className={`mt-4 text-sm font-bold ${
                  member.status === 'Active' ? 'text-green-400' : 'text-red-400'
                }`}
              >
                Status: {member.status}
              </p>
            </motion.div>
          ))}
        </motion.div>
      ) : (
        <p className="text-gray-400">No members found.</p>
      )}
    </div>
  );
}

export default MemberDirectory;
